import { useState } from "react";

export default function useDemoInfo({ values, locale }) {
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState('');
    const [error, setError] =useState(false);

    const translations = {
        en: {
            RecaptchaError: 'Recaptcha authorization error. Please contact us via email contact@abdigital.enterprises',
            RequestError: 'Please contact us via email contact@abdigital.enterprises',
            RequestSuccess: 'Thank you for submitting the contact form. We will review your message and get back to you shortly.',
        },
        fr: {
            ErrorMsg: "Erreur d'autorisation Recaptcha. Veuillez nous contacter par e-mail contact@abdigital.enterprises",
            RequestError: "Veuillez nous contacter par e-mail contact@abdigital.enterprises",
            RequestSuccess: "Merci d'avoir soumis le formulaire de contact. Nous examinerons votre message et vous répondrons sous peu.",
        },
    };
    function getTranslation(key, locale) {
        return translations[locale] && translations[locale][key] ? translations[locale][key] : translations['en'][key];
    };

    async function submitDemoRequest(e, token) {
        e.persist();
        setLoading(true);
        setError(false);
        
        const body = {
            fullname: values.fullname,
            email: values.email,
            phone: values.phone,
            msg: values.msg,
            accept: values.accept,
            token: token,
        };

            const rec = await fetch(`${process.env.GATSBY_SERVERLESS_BASE}/api/email`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(body),
            });
            
            if (!rec.ok) {
                setMessage(getTranslation('RecaptchaError', locale));
                setError(true);
            }
            if(rec.ok) {
                setMessage(getTranslation('RequestSuccess', locale));
                setError(false);
                console.log(message)
            }

            const textc = await rec.text();
            const datac = await JSON.parse(textc || '{"data": {"score": "0"}}');


        if(parseFloat(datac.data.score)>= 0.4 && parseFloat(datac.data.score)<= 1.0){   // akceptacja wyniku recaptchy 
         
            const res = await fetch(`${process.env.GATSBY_SERVERLESS_BASE}/sendDemoRequest`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(body),
            });
            if(res.ok){
                setMessage(getTranslation('RequestSuccess', locale));
            } else{
                setError(true);
                setMessage(getTranslation('RequestError', locale));
            }
            setLoading(false);
        }
    }
    return {
        submitDemoRequest,
        loading,
        message,
        error,
    };
};