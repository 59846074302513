import React from 'react';
import {PortableText} from '@portabletext/react'
import {CustomSection} from '../styles/CustomPageStyles';
import localize from '../components/localize';
import { graphql } from 'gatsby'

export default localize(PrivacyPolicy);

function PrivacyPolicy({ data, pageContext }) {
  return (
    <CustomSection>
      <h1>{data.sanityPrivacyPolicy.title[pageContext.locale]}</h1>
      <div>
        <PortableText value={data.sanityPrivacyPolicy._rawDescription} />
      </div>
    </CustomSection>
  );
}

export const query = graphql`
  query PrivacyPolicyQuery {
    sanityPrivacyPolicy{
      title {
        en
        fr
      }
      _rawDescription
    }
      sanityNavigation {
          children {
              id
          }
          navigationLink {
              title {
                  en
                  fr
              }
              pages
          }
      }
  }
`;