import styled from 'styled-components';

export const Hero = styled.div`
  height: calc(100vh - 80px);
  background-color: var(--hero-overlay);
  
  .hero-text {
    position: absolute;
    left: 50px;
    right: 40vw;
    bottom: 50px;
    color: var(--white);
    @media (max-width: 1023px) {
      max-width: 80%;
      left: 25px;  
      right: 15px;
      bottom: 50px;
      top: 140px;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
    }
    @media (max-width: 640px) {
      max-width: 100%;  
      right: 15px;
      left: 15px;
    }
    .h5 {
      @media (max-width: 1023px) {
        font-size: 18px;
        line-height: 24px;
      }
      @media (max-width: 640px) {
        font-size: 12px;
        line-height: 24px;
        margin-bottom: 10px;
      }
    }
    h1 {
      margin: 0;
        font-size: 64px;
        line-height: 72px;
        @media (max-width: 1023px) {
          font-size: 42px;
          line-height: 1.1;
        }
        @media (max-width: 640px) {
          font-size: 36px;
        }
    }
    button, a.button {
      font-size: 24px;
      line-height: 32px; 
      font-weight: 600;
      padding: 18px 52px;
      display: inline-block;
      margin-top: 25px;
      text-align: center;
      
      @media (max-width: 1023px) {
        font-size: 16px;
        line-height: 24px; 
        padding: 20px 16px;
        background-color: var(--white);
        color: var(--blue-main);
        border: 1px solid var(--blue-dark);
        min-width: 230px;
        max-width: 50vw;
        text-align: center;
        &:hover {  
        color: var(--blue-hover-btn);  
        border-color: var(--blue-hover-btn);
        background-color: var(--white);
        }
        @media (max-width: 640px) {
          max-width: 100%;
        }
      }
    }
  }
  .hero-image gatsby-image-wrapper {
    height: 100%;
  }
  .hero-image {
    position: relative;
    display: block;
    z-index: -1;
    height: 100%;
    object-fit: cover;
    object-position: center;
    picture img {
      height: auto !important;
      min-height: 100% !important;
    }
  }
`;