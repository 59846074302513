import React from 'react';
import Img from "gatsby-image";
import Masonry, {ResponsiveMasonry} from "react-responsive-masonry";

// Check if window is defined (so if in the browser or in node.js).
const isBrowser = typeof window !== "undefined"

export default function DevBlocks({ data, pageContext }) {
  if (isBrowser) {
    return (
      <div className="section-blocks dev">
        <h2>{data.title[pageContext]}</h2>
        <ResponsiveMasonry
          columnsCountBreakPoints={{350: 1, 768: 2}}
        >
          <Masonry gutter="5vw">
            {data.dev_blocks_list &&
              data.dev_blocks_list.map((block, index) => (
                <div key={"dev_blocks_" + index}>
                  {block.image && (
                    <Img fluid={block.image.asset.fluid} alt={block.title[pageContext]}/>
                  )}
                  <h3>{block.title[pageContext]}</h3>
                  <p>{block.text[pageContext]}</p>
                </div>
              ))}
          </Masonry>
        </ResponsiveMasonry>
      </div>
    );
  } else {
    return (
      <div className="section-blocks dev">
        <h2>{data.title[pageContext]}</h2>
      </div>
    )
  }
}
