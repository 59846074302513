import React from 'react';
import Img from "gatsby-image";
import Masonry, {ResponsiveMasonry} from "react-responsive-masonry";

// Check if window is defined (so if in the browser or in node.js).
const isBrowser = typeof window !== "undefined"

export default function CopyBlocks({ data, pageContext }) {
  if (isBrowser) {
    return (
      <div className="section-blocks copy">
        <h2>{data.title[pageContext]}</h2>
        <div className="grid-view">

          {data.copy_blocks_list &&
            data.copy_blocks_list.map((block, index) => (
              <div key={"copy_blocks_masonry_" + index}>
                {block.image && (
                  <Img fluid={block.image.asset.fluid} alt={block.title[pageContext]}/>
                )}
                <h3>{block.title[pageContext]}</h3>
                <p>{block.text[pageContext]}</p>
              </div>
            ))}
        </div>
      </div>
    );
  } else {
    return (
      <div className="section-blocks copy">
        <h2>{data.title[pageContext]}</h2>
        <div className="grid-view">
          {data.copy_blocks_list &&
            data.copy_blocks_list.map((block, index) => (
              <div key={"copy_block_" + index}>
                {block.image && (
                  <Img fluid={block.image.asset.fluid} alt={block.title[pageContext]}/>
                )}
                <h3>{block.title[pageContext]}</h3>
                <p>{block.text[pageContext]}</p>
              </div>
            ))}
        </div>
      </div>
    )
  }
}
