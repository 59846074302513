import React from 'react';
import localize from './localize';
import { Link } from 'gatsby';
import { navigate } from "gatsby";
import {NavStyles} from "../styles/HeaderStyles";

const translations = {
  en: {
    AboutUs: 'About Us',
    Contact: 'Contact',
    Development: 'Development',
    Copywriting: 'Copywriting',
    PrivacyPolicy: 'Privacy Policy',
  },
  fr: {
    AboutUs: 'À propos de nous',
    Contact: 'Contactez-nous',
    Development: 'Développement',
    Copywriting: 'Rédaction',
    PrivacyPolicy: 'Politique de confidentialité',
  },
};

function getTranslation(key, locale) {
  return translations[locale] && translations[locale][key] ? translations[locale][key] : translations['en'][key];
};


function Nav({ menuState, setMenuState, pageContext, locale, nav }) {
  const toggleLocale = () => {
    const newLocale = locale === 'fr' ? '' : '/fr';
    const pathName = `/${window.location.pathname.split('/').slice(-1)[0]}`;
    navigate(`${newLocale}${pathName}`);
  };
  const closeMenuOnclick = () => {
    if (menuState) {
      setMenuState(!menuState);
    }
  }

  return (
    <NavStyles className={`${menuState ? 'open' : ''}`}>
      {nav.length === 0 && (
        <ul >
          <li>
            <a className="menu-text" href={locale === 'en' ? '/#about' : `/${locale}/#about`} smooth="true"
               onClick={closeMenuOnclick}>
              {getTranslation('AboutUs',locale)}
            </a>
          </li>
          <li>
            <Link
              onClick={closeMenuOnclick}
              activeClassName={'active'}
              className="menu-text"
              to={locale === 'en' ? '/ecommerce' : `/${locale}/ecommerce`}
            >
              {getTranslation('Development',locale)}
            </Link>
          </li>
          <li>
            <Link
              onClick={closeMenuOnclick}
              activeClassName={'active'}
              className="menu-text"
              to={locale === 'en' ? '/digital-marketing' : `/${locale}/digital-marketing`}
            >
              {getTranslation('Copywriting',locale)}
            </Link>
          </li>
          <li>
            <Link
              onClick={closeMenuOnclick}
              activeClassName={menuState ? 'active' : ''}
              className="menu-text contact" to={locale === 'en' ? '/contact' : `/${locale}/contact`}>
              {getTranslation('Contact',locale)}
            </Link>
          </li>
          <li className="language">
            <span className='active'>{locale}</span>
            <button className='change-language' onClick={toggleLocale}>
              {locale === 'en' ? 'FR' : 'EN'}
            </button>
          </li>
        </ul>
      )}
      {nav.length > 0 && (
        <ul>
          {nav && nav.map((link, index) => (
            <li key={`header_menu_element_${index}`}>
              {link.pages === '#about' && (
                <Link
                onClick={closeMenuOnclick}
                activeClassName={'active'}
                className="menu-text"
                to={locale === 'en' ? `/${link.pages}` : `/${locale}${link.pages}`}
                >
                  {locale === 'en' ? link.title.en : `${link.title.fr ? link.title.fr : link.title.en}`}
                </Link>
              )}
              {link.pages !== '#about' && (
                <div>
                  {link.pages !== null && (
                    <Link
                      onClick={closeMenuOnclick}
                      activeClassName={'active'}
                      className="menu-text"
                      to={locale === 'en' ? `${link.pages}` : `/${locale}${link.pages}`}
                    >
                      {locale === 'en' ? link.title.en : `${link.title.fr ? link.title.fr : link.title.en}`}
                    </Link>
                  )}
                </div>
                )}
              </li>
          ))}
          <li>
            <Link
              onClick={closeMenuOnclick}
              activeClassName={menuState ? 'active' : ''}
              className="menu-text contact" to={locale === 'en' ? '/contact' : `/${locale}/contact`}>
              {getTranslation('Contact',locale)}
            </Link>
          </li>
          {/*<li className="language">*/}
          {/*  <span className='active'>{locale}</span>*/}
          {/*  <button className='change-language' onClick={toggleLocale}>*/}
          {/*    {locale === 'en' ? 'FR' : 'EN'}*/}
          {/*  </button>*/}
          {/*</li>*/}
        </ul>
      )}
        </NavStyles>
  );
}

export default localize(Nav);