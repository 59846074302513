import React from 'react';
import {graphql} from "gatsby";
export default function FourOhFourPage() {
  return (
    <>
      <div id="not-found">
        <h1>404</h1>
        <p>That page doesn't exist</p>
      </div>
    </>
  );
}


export const query = graphql`
    query PageNotFountQuery {
        sanityContactBanner {
            title {
                en
                fr
            }
            subtitle {
                en
                fr
            }
            image {
                asset {
                    fluid(sizes: "(max-width: 2400px) 100vw, 2400px") {
                        ...GatsbySanityImageFluid
                    }
                }
            }
        }
        sanityContactForm {
            title {
                en
                fr
            }
            description {
                en
                fr
            }
            regulations {
                en {
                    _rawChildren
                }
                fr {
                    _rawChildren
                }
                _rawEn(resolveReferences: {maxDepth: 100})
                _rawFr(resolveReferences: {maxDepth: 100})
            }
        }
        sanityFooter {
            phone
            email
            address_line1
            address_line2
            social_media_list {
                website
                link
            }
            footer_nav_list {
                link
                title {
                    en
                    fr
                }
            }
        }
        sanityNavigation {
            children {
                id
            }
            navigationLink {
                title {
                    en
                    fr
                }
                pages
            }
        }
    }
`;