import React from 'react';
import { Link } from 'gatsby';
import {LogoStyles} from "../styles/HeaderStyles";

export default function Logo({ menuState, setMenuState, locale }) {
  const homepagePath = locale === 'en' ? '/' : `/${locale}`;
  const closeMenuOnclick = () => {
    if (menuState) {
      setMenuState(!menuState);
    }
  }

  return (
    <LogoStyles>
      <Link to={homepagePath} title="Scroll to top of the site" onClick={closeMenuOnclick} rel="noreferrer">
        <img className="logoDesktop" src="/logo.svg" alt="Logo AB Digital Enterprises" width="363" height="50" />
        <img className="logoMobile" src="/logo_mobile.svg" alt="Logo AB Digital Enterprises" width="80" height="48" />
      </Link>
    </LogoStyles>
  );
}
